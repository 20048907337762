/**
 * Border classes
 * @path: utilities/borders
 */

.u-border-bottom{
    border-bottom:.1rem solid $medium-gray;
}
.u-border {
    border: 1px solid #AAAAAA;
}

.u-border-on-focus {
    &:focus {
        border: 1px solid #AAAAAA
    }
}