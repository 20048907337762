/**
 * Foundation for Sites by ZURB
 * Version 6.1.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
// Disable warnings in terminal
$show-warnings: false;

// Import custom settings
@import 'settings/custom';

// Import settings_frontend.scss. For overwriting foundation variables
@import 'settings/foundation';

// Import all Foundation components
@import '../../../../node_modules/foundation-sites/scss/foundation.scss';

// Include the right mixins
@if $global-flexbox {
    @include foundation-flex-classes;
    @include foundation-flex-grid;
}
@else {
    @include foundation-grid;
}

@include foundation-global-styles;
@include foundation-typography;
// @include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
// @include foundation-float-classes;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-flex-video;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-menu;
@include foundation-menu-icon
@include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
// @include foundation-table;
@include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
@include foundation-tooltip;
// @include foundation-top-bar;

// Tools
@import 'tools/functions';
@import 'tools/mixins';

// Generic
@import 'generic/normalize';

// Elements
@import 'elements/body';

// Objects
@import 'objects/grid';
@import 'objects/media-object';
@import 'objects/off-canvas';
@import 'objects/wrappers';
@import 'objects/video';

// Components
@import 'components/accordion';
@import 'components/alert-box';
@import 'components/avatars';
@import 'components/banner';
@import 'components/buttons';
@import 'components/card';
@import 'components/collage';
@import 'components/cookie';
@import 'components/file-input';
@import 'components/filter';
@import 'components/footer';
@import 'components/forms';
@import 'components/grid-slot';
@import 'components/header';
@import 'components/headings';
@import 'components/highlight-section';
@import 'components/icons';
@import 'components/images';
@import 'components/label';
@import 'components/lightcase';
@import 'components/links';
@import 'components/lists';
@import 'components/loader';
@import 'components/navigation';
@import 'components/overlay';
@import 'components/offcanvas';
@import 'components/page-title';
@import 'components/paint-spatter';
@import 'components/recaptcha';
@import '../global/components/select2';
@import 'components/slider';
@import 'components/splash';
@import 'components/squeezebox';
@import 'components/sticky-nav';
@import 'components/switch';
@import 'components/table';
@import 'components/tabs';
@import 'components/tooltip';
@import 'components/usp-slider';
@import 'components/visuals';

// Utilities
@import 'utilities/animate';
@import 'utilities/borders';
@import 'utilities/colors';
@import 'utilities/display';
@import 'utilities/elevation';
@import 'utilities/flex-helpers';
@import 'utilities/height';
@import 'utilities/position';
@import 'utilities/scroll';
@import 'utilities/spacing';
@import 'utilities/typography';
@import 'utilities/width';