/**
* Buttons
* @path: components/buttons
*/

// Button
.c-button{
    @include button;
    font-family:$header-font-family;
    text-transform:uppercase;
    &::before{
        margin-right:.5rem;
        content:'>';
    }
    &.c-button-close{
        &::before{
            margin-right:.5rem;
            content:'X';
        }
    }
    &.c-back-button{
        &::before{
            margin-right:.5rem;
            content:'<'; 
        } 
    }
    &-clear {
        background-color: transparent;
    }

    /** 
     * Creates classes for button sizes
     *
     * @var {Map} $button-sizes - settings/foundation
     * @output e.g. '.small'
     */
    @each $size, $value in map-remove($button-sizes, default) {
        &.#{$size} {
            font-size: $value;
        }
    }

    // Disabled style
    &.disabled,
    &[disabled] {
        @include button-disabled;
        /**
         * Creates classes to neutralize background/border colors
         * 
         * @var {Map} $button-palette - settings/foundation
         * @output e.g. '.primary'
         */
        @each $name, $color in $button-palette {
            &.#{$name} {
                @include button-disabled($color, auto);
            }
        }
    }
    // Transparent
    &.u-bg-transparent{
        padding:0;
        color:$primary-color;
        align-self:center;
        &:hover, &:focus{
            background-color:transparent;
        }
    }
}

// Button-group
.c-button-group{
    @include button-group;

    /** 
     * Creates classes for button sizes
     *
     * @var {Map} $button-sizes - settings/foundation
     * @output e.g. '.small'
     */
     @each $size, $value in map-remove($button-sizes, default) {
        &.#{$size} #{$buttongroup-child-selector} {
            font-size: $value;
        }
    }
}