/**
 * Horizontal and vertical spacing
 * @path: utilities/spacing
 */

/**
 * Create helpers classes for top and bottom padding of elements
 *
 * @var {Map} $v-spacing - settings/custom
 * @var {List} $spacing-type - determines for which spacing types the classes should be created
 * @output e.g. '.u-padding-top-small'
 */
@each $breakpoint, $sizes in $v-spacing {
    @include breakpoint($breakpoint) {
        @each $spacing-type in margin, padding {
            @each $size, $value in $sizes {
                @if( $size == 'default') {
                    .u-#{$spacing-type}-vertical{ #{$spacing-type}-top:#{$value}; #{$spacing-type}-bottom:#{$value}; }
                    .u-#{$spacing-type}-top{ #{$spacing-type}-top:#{$value}; }
                    .u-#{$spacing-type}-bottom{ #{$spacing-type}-bottom:#{$value}; }
                } @else {
                    .u-#{$spacing-type}-vertical-#{$size}{ #{$spacing-type}-top:#{$value}; #{$spacing-type}-bottom:#{$value}; }
                    .u-#{$spacing-type}-top-#{$size}{ #{$spacing-type}-top:#{$value}; }
                    .u-#{$spacing-type}-bottom-#{$size}{ #{$spacing-type}-bottom:#{$value}; }
                }
            }
        }
    }
}

.u-padding-horizontal-tiny { padding-left: 1rem; padding-right: 1rem; }

.u-no-margin-bottom{ margin-bottom:0 !important; }
.u-no-padding{ padding:0 !important; }
.u-no-padding-left{ padding-left:0 !important; }
.u-decrease-padding-wrapper{
    .u-padding-vertical{
        padding-top:4rem;
        padding-bottom:4rem; 
    }
}
.u-decrease-padding-bottom-wrapper{
    .u-padding-vertical{
        padding-bottom:4rem; 
    }
}