/**
 * Card (mixins used from Foundation)
 * @path: components/card
 */

.c-cards-wrapper{
    .column{
        max-width:32rem;
        padding-left:1.5rem;
        padding-right:1.5rem;
    }

    .c-card-subscription {
        max-width: none;
    }
}

.c-card {
    @include card-container;
    
    &.is-featured {
        position: relative;
        z-index: 1;
        background-color: $primary-color;
        color: $white !important;
    }
}

.vacancy-card {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content:space-between;
}

.c-card-image{
    width:100%;
    height:18.5rem;
    background-color:$white;
    border:1rem solid $card-background;
    border-bottom:0;
    justify-content:center;
    display:flex;
    img{
        max-width:25rem;
        max-height:14.5rem;
        align-self:center;
        flex:0 0 auto;
    }
}

.c-card-header{
    margin-bottom:3rem;
    padding:4.5rem 3.5rem 0 3.5rem;
}

.c-card-content{
    @include card-section;
}

.c-card-footer{
    padding:0 3.5rem 4.5rem 3.5rem;
    .c-button-group{
        justify-content:space-between;
        &::before, &::after{
            content:none;
        }
    }
}

@include breakpoint(medium) {
    .c-card{ height:100%; }
}